import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useTranslation } from 'react-i18next';
import { FooterSection } from '../Home/FooterSection/FooterSection';

import {
  ExtensionContainer,
  MainHeader,
  VideosHeader,
  BookieContainer,
  HeaderPupsikSubContainer,
  CapabilitiesContainer,
  Capability,
  DownloadButton,
  VideoCardContainer,
  VideoCard,
  VideoCardHeader,
  VideoCardBody,
  VideoCardButton,
  Title
} from "./Extension.styled";
import { NavBarMain } from "..";
import { useUser } from "../../hooks";
import { checkIfTokenStillValid } from "../../repositories/utils";

const bookies = [
 {
  bookie: "Winamax",
  capabilities: {
    navigation: true,
    betting: true,
  }
 },
  {
    bookie: "Retabet",
    capabilities: {
      navigation: true,
      betting: true,
    },
  },
  {
    bookie: "888",
    capabilities: {
      navigation: true,
      betting: true,
    },
  },
  {
    bookie: "Betfair",
    capabilities: {
      navigation: true,
      betting: true,
    },
  },
  {
    bookie: "Dafabet",
    capabilities: {
      navigation: true,
      betting: true,
    },
  },
  {
    bookie: "Interwetten",
    capabilities: {
      navigation: true,
      betting: true,
    },
  },
  {
    bookie: "Betway",
    capabilities: {
      navigation: true,
      betting: true,
    },
  },
  {
    bookie: "Bwin",
    capabilities: {
      navigation: true,
      betting: true,
    },
  },
  {
    bookie: "Olybet",
    capabilities: {
      navigation: true,
      betting: true,
    },
  },
  {
    bookie: "Pokerstars",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Versus",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Sportium",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Paf",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Casumo",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Leovegas",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Enracha",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Bet365",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "1xbet",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Pinnacle",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Optibet",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Coolbet",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Coolbet",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Yaasscasino",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
  {
    bookie: "Daznbet",
    capabilities: {
      navigation: true,
      betting: false,
    },
  },
];

export const Extension = ({ history }) => {
  const { getExtensionConfiguration } = useUser();
  const [configuration, setConfiguration] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchConfiguration() {
      await checkIfTokenStillValid(history);
      const response = await getExtensionConfiguration();
      setConfiguration(response);
    }
    fetchConfiguration();
  }, [history, getExtensionConfiguration]);

  return (
    <>
      <ExtensionContainer data-configuration={JSON.stringify(configuration)}>
      <NavBarMain currentPage="extension" history={history}/>
        <MainHeader>
              <Title>Extension</Title>
              <DownloadButton
                variant="secondary"
                size="lg"
                href="https://extension.betsmarter.app/043853a1-51a5-4782-9af3-da8fab517d73.zip"
              >
                {t('downloadButton')}
                <h5>v3.2.0</h5>
              </DownloadButton>{" "}
              <Container style={{ marginTop: "20px" }}>
                <BookieContainer>
                  {bookies.map((bookie) => (
                    <HeaderPupsikSubContainer>
                      {bookie.bookie}
                      <CapabilitiesContainer>
                        {Object.keys(bookie.capabilities).map((capability) => (
                          <Capability enabled={bookie.capabilities[capability]}>
                            {bookie.capabilities[capability] ? (<i className="fa-solid fa-check"></i>) : (<i className="fa-solid fa-xmark"></i>)} {t(capability)}
                          </Capability>
                        ))}
                      </CapabilitiesContainer>
                    </HeaderPupsikSubContainer>
                  ))}
                </BookieContainer>
              </Container>
        </MainHeader>
        <VideosHeader>
          <Title>Videos</Title>
          <VideoCardContainer>
              <VideoCard>
                <VideoCardHeader>
                  <h4>
                    Instalar Extensión
                  </h4>
                </VideoCardHeader>
                <VideoCardBody>
                  En este vídeo mostramos como instalar la extensión en Google
                  Chrome
                </VideoCardBody>
                <VideoCardButton
                  variant="primary"
                  target="_blank"
                  href="https://www.loom.com/share/a5d334b59a6e4f7d9e9de25a213db0d5"
                >
                  Ir al Video
                </VideoCardButton>
              </VideoCard>
              <VideoCard>
                <VideoCardHeader>
                  <h4>
                    Actualizar Extensión
                  </h4>
                </VideoCardHeader>
                <VideoCardBody>
                  En este vídeo mostramos como actualizar la extensión a una
                  versión más moderna
                </VideoCardBody>
                <VideoCardButton
                  variant="primary"
                  target="_blank"
                  href="https://www.loom.com/share/732a1c7c09fa42399d7c203c70d63511"
                >
                  Ir al vídeo
                </VideoCardButton>
              </VideoCard>
              <VideoCard>
                <VideoCardHeader>
                  <h4>
                    Sincronizar extensión
                  </h4>
                </VideoCardHeader>
                <VideoCardBody>
                  En este vídeo mostramos como sincronizar la extensión para su
                  uso en distintos navegadores
                </VideoCardBody>
                <VideoCardButton
                  variant="primary"
                  target="_blank"
                  href="https://youtu.be/xRc2fjqS0V0"
                >
                  Ir al vídeo
                </VideoCardButton>
              </VideoCard>
          </VideoCardContainer>
        </VideosHeader>
        <FooterSection />
      </ExtensionContainer>
    </>
  );
};
