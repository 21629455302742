import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  CartesianGrid
} from "recharts";
import { useTranslation } from 'react-i18next';
import { NavBarMain } from "..";

import "./Events.styled";
import {
  EventsContainer,
  Title,
  Subcontainer,
  SportIcon,
  LeagueName,
  LegendContainer,
  ChartContainer,
  TotalEventsContainer,
  TooltipContainer,
  TooltipItem,
  TooltipLabel,
  TooltipItemContainer,
  TooltipIcon,
  TooltipText,
  TooltipValue,
  TooltipSeparator,
  TooltipTotalContainer,
  TooltipTotal,
  TooltipTotalValue,
  FilterContainer,
  RightContainer,
  SportsContainer,
  NameContainer,
  NameSearchContainer
} from "./Events.styled";
import { useEvents } from "../../hooks";
import { Table, Pagination, Form } from "react-bootstrap";
import { getSportIcon, getSportIconColor } from "../../utils/events";
import { checkIfTokenStillValid } from "../../repositories/utils";

const HOUR_MS = 60 * 60 * 1000;

const hoursData = Array.from({ length: 24 }, (_, i) => ({ name: i }));

const getCapitalizedWord = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const Events = ({ history }) => {
  const { getUpcomingEvents } = useEvents();
  const [events, setEvents] = useState({});

  const [activePage, setActivePage] = useState(1);
  const [pages, setPages] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedSports, setSelectedSports] = useState(["Football", "Basketball", "Tennis", "Baseball", "Hockey", "AmericanFootball"]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filterText, setFilterText] = useState("");

  const [filterBy, setFilterBy] = useState('days'); // 'hours' or 'days'
  const { t } = useTranslation();

  const filterOptions = [
    { value: 'days', label: t('days') },
    { value: 'hours', label: t('hours') },
  ];

  useEffect(() => {
    async function validateToken() {
      await checkIfTokenStillValid(history);
    }
    validateToken();
  }, [history]);

  const CustomLegend = ({ payload, onClick }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
      {payload.map((entry, index) => (
        <LegendContainer 
          key={`item-${index}`} 
          onClick={() => onClick(entry.value)}
          selected={selectedSports.includes(entry.value)}
        >
          <span 
            style={{ 
              display: 'inline-block', 
              width: '10px', 
              height: '10px', 
              borderRadius: '50%', 
              backgroundColor: entry.color, 
              marginRight: '10px' 
            }}
          ></span>
          <span>{t(entry.value.toLowerCase())}</span>
        </LegendContainer>
      ))}
    </div>
  );

  const data = Object.values(events)
    .flatMap((events) =>
      events.map(({ name, sport, startedAt }) => ({ name, sport, startedAt }))
    )
    .sort((a, b) => new Date(a.startedAt) - new Date(b.startedAt));

    useEffect(() => {
      const currentTime = Date.now();
      const data = Object.values(events)
        .flatMap((events) =>
          events.map(({ name, sport, league, startedAt }) => ({ name, sport, league, startedAt }))
        )
        .filter(
          ({ startedAt }) =>
            currentTime - new Date(startedAt) <= 3 * 60 * 60 * 1000
        )
        .sort((a, b) => new Date(a.startedAt) - new Date(b.startedAt));
    
      let startTimes;
      let intervalCount;
      let currentPage;
      let minTime;
      let maxTime;
    
      if (filterBy === 'hours') {
        startTimes = data.map(({ startedAt }) => {
          const time = new Date(startedAt);
          time.setMinutes(0);
          time.setSeconds(0);
          time.setMilliseconds(0);
          return time.getTime();
        });
        minTime = Math.min(...startTimes);
        maxTime = Math.max(...startTimes);
        intervalCount = Math.ceil((maxTime - minTime) / HOUR_MS);
    
        let now = Date.now();
        currentPage = Math.floor((now - minTime) / HOUR_MS) + 1;
        currentPage = Math.max(1, Math.min(currentPage, intervalCount));
      } else {
        startTimes = data.map(({ startedAt }) => {
          const time = new Date(startedAt);
          time.setHours(0);
          time.setMinutes(0);
          time.setSeconds(0);
          time.setMilliseconds(0);
          return time.getTime();
        });
        minTime = Math.min(...startTimes);
        maxTime = Math.max(...startTimes);
        intervalCount = Math.ceil((maxTime - minTime) / (24 * HOUR_MS));
    
        let now = Date.now();
        currentPage = Math.floor((now - minTime) / (24 * HOUR_MS)) + 1;
        currentPage = Math.max(1, Math.min(currentPage, intervalCount));
      }
    
      const pages = [];
      for (let i = 0; i < intervalCount; i++) {
        const startTime = new Date(minTime + i * (filterBy === 'hours' ? HOUR_MS : 24 * HOUR_MS));
        const endTime = new Date(minTime + (i + 1) * (filterBy === 'hours' ? HOUR_MS : 24 * HOUR_MS));
        const pageData = data.filter(({ startedAt }) => {
          const time = new Date(startedAt).getTime();
          return time >= startTime.getTime() && time < endTime.getTime();
        });
        pages.push(pageData);
      }
    
      setPages(pages);
      setActivePage(currentPage);
    }, [events, filterBy]);

    const visibleData = (pages || [])[activePage - 1]?.filter(
      ({ sport, name, league }) => (!selectedSport || sport.name === selectedSport) && (name.toLowerCase().includes(filterText.toLowerCase()) || (league?.name || '').toLowerCase().includes(filterText.toLowerCase()))
    );

  const handleSportChange = (event) => {
    setSelectedSport(event.target.value);
  };

  const sports = Object.keys(
    data.reduce((sports, { sport }) => {
      sports[sport.name] = true;
      return sports;
    }, {})
  ).sort();

  const handlePageChange = (page) => {
    if (filterBy === 'hours') {
      // Change page by hours
      setActivePage(page);
    } else {
      // Change page by days
      const date = Object.keys(dateOptions).sort((a, b) => new Date(a) - new Date(b))[page - 1];
      setSelectedDate(new Date(dateOptions[date][0]));
      setActivePage(page);
    }
  };

  useEffect(() => {
    async function fetchEvents() {
      const response = await getUpcomingEvents();
      setEvents(response);
    }
    fetchEvents();
  }, []);

  const sortedData = Object.entries(events)
    .filter(([dateTime]) => {
      const date = new Date(dateTime);
      return (
        date.getDate() === selectedDate.getDate() &&
        date.getMonth() === selectedDate.getMonth() &&
        date.getFullYear() === selectedDate.getFullYear()
      );
    })
    .map(([dateTime, items]) => ({
      dateTime: new Date(dateTime),
      ...items.reduce((acc, { sport }) => {
        acc[getCapitalizedWord(sport.name)] = (acc[getCapitalizedWord(sport.name)] || 0) + 1;
        return acc;
      }, {}),
    }))
    .sort((a, b) => a.dateTime - b.dateTime);

    const chartData = Object.values(sortedData)
    .map(data => ({
      name: new Date(data.dateTime).getHours(),
      ...data,
    }))
    .filter(data => selectedSports.length === 0 || selectedSports.some(sport => data[sport]));
    
    const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month} ${hours}:${minutes}`;
  };

  const dateOptions = Object.keys(events)
  .sort((a, b) => new Date(a) - new Date(b))
  .reduce((acc, _date) => {
    const date = new Date(_date);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");

    const naturalDate =  `${day}/${month}`;
    if (acc[naturalDate]) {
      acc[naturalDate].push(date);
    } else {
      acc[naturalDate] = [date];
    }
    return acc;
  }, {});

  const filteredChartData = chartData.map(item => {
    const newItem = { ...item };
    Object.keys(newItem).forEach(key => {
      if (!selectedSports.includes(key) && key !== 'name') {
        delete newItem[key];
      }
    });
    return newItem;
  });

  const handleSportClick = (sport) => {
    setSelectedSports((prevSports) => {
      if (prevSports.length === 1 && prevSports.includes(sport)) {
        // If only one sport is selected and it is the clicked sport, select all sports
        return ["Football", "Basketball", "Tennis", "Baseball", "Hockey", "AmericanFootball"];
      } else if (prevSports.length === 0 || prevSports.length === 6) {
        // If no sports or all sports are selected, select only the clicked sport
        return [sport];
      } else if (!prevSports.includes(sport)) {
        // If the clicked sport is not selected, add it to the array
        return [...prevSports, sport];
      } else {
        // If the clicked sport is already selected, remove it from the array
        return prevSports.filter((s) => s !== sport);
      }
    });
  };
  
  const mergedData = hoursData.map(hourData => {
    const matchingData = filteredChartData.find(data => data.name === hourData.name);
    return matchingData ? matchingData : hourData;
  });

  const handleFilterChange = (value) => {
    setFilterBy(value);
  };

  const sportsBars = {
    Football: {
      color: '#82ca9d',
      order: 0,
    },
    Basketball: {
      color: '#8884d8',
      order: 1,
    },
    Tennis: {
      color: '#2884d8',
      order: 2,
    },
    Baseball: {
      color: 'rgb(53, 102, 120)',
      order: 3,
    },
    Hockey: {
      color: 'rgb(255, 99, 132)',
      order: 4,
    },
    AmericanFootball: {
      color: '#5820c8',
      order: 5,
    },
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <TooltipLabel>{label}:00</TooltipLabel>
            {payload.map((pld) => (
              <TooltipItem>
                <TooltipItemContainer>
                  <TooltipIcon color={pld.fill} />
                  <TooltipText>{t(pld.dataKey.toLowerCase())}</TooltipText>
                </TooltipItemContainer>
                <TooltipValue>{pld.value}</TooltipValue>
              </TooltipItem>
            ))}
            <TooltipSeparator />
            <TooltipTotalContainer>
              <TooltipTotal>Total</TooltipTotal>
              <TooltipTotalValue>{payload.reduce((acc, pld) => acc + pld.value, 0)}</TooltipTotalValue>
            </TooltipTotalContainer>
        </TooltipContainer>
      );
    }
  
    return null;
  };
  return (
    <>
      <EventsContainer>
        <NavBarMain currentPage="events" history={history}/>
      <Subcontainer>
        <ChartContainer>
              <Title>{t('events')}</Title>
              <ResponsiveContainer width="100%" height={400} minWidth={300}>
                <BarChart
                  data={mergedData}
                  
                >
                  <XAxis dataKey="name" stroke="white" axisLine={false} tickLine={false} tickMargin={5} />
                  <YAxis stroke="white" axisLine={false} tickLine={false} tickMargin={5} />
                  <Tooltip content={<CustomTooltip/>} cursor={{ fill: "transparent" }} />
                  <Legend content={<CustomLegend onClick={(sport) => {
                    handleSportClick(sport);
                  }} />} />
                  <CartesianGrid strokeDasharray="3 0" vertical={false} stroke="rgba(255, 255, 255, 0.1)" />
                  {Object.entries(sportsBars).map(([sport, { color, order }]) => <Bar
                        key={sport}
                        dataKey={sport}
                        stackId="a"
                        fill={selectedSports.includes(sport) ? color : "transparent"}
                        order={order}
                      />)}
                </BarChart>
              </ResponsiveContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 0,
                }}
              >
                <Pagination>
                  {Object.entries(dateOptions)
                  .filter(([, times]) => times.length > 5)
                  .map(([naturalDate]) => {
                    const day = selectedDate.getDate().toString().padStart(2, "0");
                    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");

                    const naturalDateForSelcted =  `${day}/${month}`;

                    const active = naturalDate === naturalDateForSelcted;
                    return (
                      <Pagination.Item
                        key={naturalDate}
                        onClick={() => {
                          setSelectedDate(new Date(dateOptions[naturalDate][0]));
                          if (filterBy === 'days') {
                            const page = Object.keys(dateOptions).sort((a, b) => new Date(a) - new Date(b)).indexOf(naturalDate) + 1;
                            setActivePage(page)}
                          }
                        }
                        active={active}
                        linkClassName="paginationCustom"
                      >
                        {naturalDate}
                      </Pagination.Item>
                    );
                  })}
                </Pagination>
              </div>
        </ChartContainer>
          <div className="container" style={{ maxWidth: '100%', padding: '0px' }}>
          <Pagination>
            {filterOptions.map((option, index) => (
              <Pagination.Item
                key={index}
                active={filterBy === option.value}
                onClick={() => handleFilterChange(option.value)}
              >
                {getCapitalizedWord(option.label)}
              </Pagination.Item>
            ))}
          </Pagination>
            <Pagination style={{ overflowY: "auto" }}>
              {filterBy === 'hours' ? pages.map((page, index) => (
                page.length > 0 &&
                <Pagination.Item
                  key={index}
                  active={index + 1 === activePage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {formatDate(new Date(page[0]?.startedAt))}
                </Pagination.Item>
              )) : Object.entries(dateOptions).filter(([, times]) => times.length > 5).map(([naturalDate], index) => (
                <Pagination.Item
                  key={naturalDate}
                  onClick={() => handlePageChange(index + 1)}
                  active={index + 1 === activePage}
                >
                  {naturalDate}
                </Pagination.Item>
              ))}
            </Pagination>
            <FilterContainer>
              <TotalEventsContainer>
                <b>{visibleData?.length}</b> {t('events')}
              </TotalEventsContainer>
              <RightContainer>
              <SportsContainer>
              <Form.Label style={{ marginBottom: 0 }}><i className="fa-solid fa-futbol" /></Form.Label>
                <Form.Control
                  as="select"
                  value={selectedSport}
                  onChange={handleSportChange}
                >
                  <option value="">{t('AllSports')}</option>
                  {sports.map((sport, index) => (
                    <option key={index} value={sport}>
                      {t(sport)}
                    </option>
                  ))}
                </Form.Control>
              </SportsContainer>
              <NameContainer>
                <NameSearchContainer>
                  <Form.Control
                    type="text"
                    value={filterText}
                    placeholder={t('FilterByText')}
                    onChange={(event) => setFilterText(event.target.value)}
                  />
                </NameSearchContainer>
                <Form.Label style={{ marginBottom: 0 }}><i className="fa-solid fa-magnifying-glass" /></Form.Label>
              </NameContainer>
              </RightContainer>
            </FilterContainer>
            <Table hover>
              <thead>
                <tr>
                  <th>{t('Sport')}</th>
                  <th>{t('Name')}</th>
                  <th>{t('StartTime')}</th>
                </tr>
              </thead>
              <tbody>
                {visibleData?.map(({ name, sport, league, startedAt }, index) => (
                  <tr key={index} style={{ backgroundColor: index % 2 ? '#111E12' : '#1d2d1d'}}>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                      <SportIcon
                        className="sport"
                        src={getSportIcon(sport.name)}
                        filter={getSportIconColor(sport.name)}
                      />
                      {t(sport.name)}
                    </td>
                    <td>{name} <LeagueName>| {league.name}</LeagueName></td>
                    <td>{formatDate(new Date(startedAt))}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Subcontainer>
      </EventsContainer>
    </>
  );
};
