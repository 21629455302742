import styled from "styled-components";
import Button from "react-bootstrap/Button";

export const ExtensionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;

  .text-muted {
    margin-bottom: 0px !important;
  }
`

export const Title = styled.h1`
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: -0.04em;
  padding-bottom: 30px;
  width: 100%;

  font-size: 30px;
  align-self: flex-start;
`;

export const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  width: 100%;
  max-width: 1600px;
  padding: 20px;
  margin-top: 30px;

  background: #1d2d1d;
  border-radius: 10px;
`

export const VideosHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  width: 100%;
  max-width: 1600px;
  padding: 20px;
  margin-top: 30px;

  background: #1d2d1d;
  border-radius: 10px;
`

export const DownloadButton = styled(Button)`
  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: -0.04em;
  font-size: 20px;

  :hover {
    background-color: rgb(23, 128, 61);
    color: white;
  }  
  `

export const BookieContainer = styled.div`
  display: flex;
  margin-top: 20px; 
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
`

export const HeaderPupsikSubContainer = styled.div`
  gap: 1px;
  align-items: end;
  // background-color: #111e12;
  background-color: white;
  color: #111e12;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
`

export const CapabilitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 7px;
  background-color: #111e12;
  border-radius: 5px;
  color: white;
`

export const Capability = styled.div`
  color: ${({ enabled }) => enabled ? 'white' : '#758b77'};
`;

export const VideoCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 7px;
  justify-content: center;
  padding-bottom: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const VideoCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
  background-color: #111e12;
  border-radius: 5px;
  color: white;
  width: 100%;
  max-width: 350px;
  justify-content: space-between;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

export const VideoCardHeader = styled.div`
  h4 {
    font-weight: bold;
    margin-bottom: 0px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const VideoCardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  color: rgba(255, 255, 255, 0.8);
`
export const VideoCardButton = styled(Button)`
  background-color: #e9fd8c;
  color: #111e12;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  width: 150px;
  margin-left: auto;
`