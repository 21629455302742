import React, { useState, useMemo } from "react";
import { Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { getSportIcon, getSportIconColor, getScoreForArbs, getSubScoreForArbs, getGoalIcon, checkIfScoreChanged } from "../../utils/events";

// Define the fixed order of sports
const SPORT_ORDER = [
  "football",
  "basketball",
  "tennis",
  "americanFootball",
  "hockey",
  "baseball",
];

export const EventsModal = React.memo(function EventsModal({
  show,
  setVisible,
  events,
}) {
  const { t } = useTranslation();
  const close = () => setVisible(false);
  const [selectedSport, setSport] = useState('all'); // Change default to 'all'

  // Sort the sports based on the fixed order
  const sortedSports = useMemo(() => {
    return ['all', ...SPORT_ORDER.filter(sport => events[sport])];
  }, [events]);

  // Calculate total events count
  const totalEventsCount = useMemo(() => {
    return Object.values(events).reduce((total, sportEvents) => total + sportEvents.length, 0);
  }, [events]);

  return (
    <Dialog open={show} onClose={close} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4 overflow-y-auto">
        <Dialog.Panel className="w-full max-w-4xl rounded-xl bg-[#111E12] text-white font-montserrat my-8">
          <Dialog.Title className="text-4xl font-medium p-4 flex justify-between items-center">
            {t('events')}
            <button onClick={close} className="text-gray-400 hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </Dialog.Title>
          <div className="p-4">
            <div className="overflow-x-auto">
              <nav className="flex rounded-lg mb-4 whitespace-nowrap">
                {sortedSports.map((sportName) => (
                  <button
                    key={sportName}
                    onClick={() => setSport(sportName)}
                    className={`flex-none py-2 px-4 flex items-center justify-center ${selectedSport === sportName ? 'bg-[#1C2D1C]' : ''} rounded-lg hover:bg-[#2A3F2A] transition-colors duration-200`}
                  >
                    {sportName !== 'all' && (
                      <img
                        src={getSportIcon(sportName)}
                        alt={sportName}
                        className="w-5 h-5 mr-2"
                        style={{ filter: getSportIconColor(sportName) }}
                      />
                    )}
                    <span className="capitalize">{t(sportName)}</span>
                    <span className="ml-2 bg-white text-black text-xs font-bold px-2 py-1 rounded-full">
                      {sportName === 'all' ? totalEventsCount : events[sportName]?.length}
                    </span>
                  </button>
                ))}
              </nav>
            </div>
            <div className="max-h-[60vh] overflow-y-auto">
              <ul className="space-y-2">
                {(selectedSport === 'all' ? Object.values(events).flat() : events[selectedSport])
                  ?.sort((a, b) => b.bookies - a.bookies)
                  .map((event) => (
                    <li key={event.id} className="flex items-center justify-between bg-[#1C2D1C] p-3 rounded-lg">
                      <div className="flex flex-col">
                        <span className="text-white">{event.name}</span>
                        <span className="text-[#758b77] text-sm">{event.leagueName}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <span className="bg-[#DBD7FF] text-[#1d2d1d] px-2 py-1 rounded font-bold">
                          {getScoreForArbs(event)}
                        </span>
                        {getSubScoreForArbs(event) && (
                          <span className="bg-[#028394] text-white px-2 py-1 rounded font-bold">
                            {getSubScoreForArbs(event)}
                          </span>
                        )}
                        {checkIfScoreChanged(event) && (
                          <div className="bg-red-600 text-white px-2 py-1 rounded flex items-center">
                            <img src={getGoalIcon(event.sport?.name)} alt="Goal" className="w-4 h-4 mr-1" />
                            <span className="font-bold animate-pulse">GOAL</span>
                          </div>
                        )}
                        <span className="bg-white text-black text-xs font-bold px-2 py-1 rounded-full">
                          {event.bookies}
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
});
